.container {
    position: relative;
    display: flex;
    justify-content: space-around;
    /*align-items: center;*/
    flex-wrap: wrap;
    gap: 100px 50px;
    padding: 100px 50px;
}

.container .card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 350px;
    max-width: 100%;
    height: 300px;
    background: white;
    border-radius: 20px;
    transition: 0.8s;
    box-shadow: 0 35px 80px rgba(252, 251, 251, 0.342);
}

.container .card:hover {
    height: 400px;
    cursor: pointer;
}

.container .card .img-box {
    position: absolute;
    top: 20px;
    width: 300px;
    height: 240px;
    background: #333;
    border-radius: 12px;
    overflow: hidden;
    transition: 0.8s;
}

.container .card:hover .img-box {
    top: -100px;
    scale: 0.75;
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
}

.container .card .img-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container .card .content {
    position: absolute;
    top: 252px;
    width: 100%;
    height: 0px;
    padding: 0 30px;
    text-align: center;
    overflow: hidden;
    transition: 0.8s;
}


.container .card:hover .content {
    top: 130px;
    height: 250px;
}

.container .card .content h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: blue;
}
.card .content .box{
    overflow: hidden;
    color: #333;
    position: relative;
    height: 120px; 
    padding: 1rem;
}
.card .content .box:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, transparent, #fff 100%);
  }
.card .content p
{
    text-align: center;
}


.container .card .content p {
    position: relative;
    top: 15px;
    display: inline-block;
    padding: 12px 25px;
    text-decoration: none;
    background: var(--clr);
    color: black;
    font-weight: 500;
    font-size: 18px;
}

.container .card .content p:hover {
    opacity: 0.8;
}

@media (max-width: 480px) {
    .container .card {
        width: 230px;
        border-radius: 15px;
    }

    .container .card .img-box {
        width: 185px;
        border-radius: 10px;
    }

    .container .card .content p {
        font-size: 0.8rem;
    }

    .container .card .content a {
        font-size: 0.9rem;
    }
}
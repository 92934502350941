* {

  color: whitesmoke;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /*color: white;*/
  background: linear-gradient(20deg, #000000b4 0%, #686868 95%), #3f3131;

}

.content {
  display: flex;
  flex-grow: 1;
  margin-right: auto;
  margin-left: auto;
  width: 70%;
  flex-direction: column;
  border-radius: 10px;
  /*padding: 50px;
  /*background: blue;*/
}

.content .contentBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}

.content .info {
  max-width: 600px;
}

.info h2 {
  font-size: 35px;
  text-align: center;
}

li {
  font-size: 20px;
  margin: 10px;
}

.linc {
  font-size: 25px;
  text-align: center;
}

.linc:hover {
  cursor: pointer;
}

p {
  font-size: 25px;
  text-indent: 5%;
  text-align: justify;
}

.imgPages {
  margin: 0px;
  /*width: 450px;*/
  max-height: 400px;
  border-radius: 12px;
}

.imgMe {
  margin: 0px;
  /*width: 450px;*/
  max-height: 666px;
  border-radius: 12px;

}




@media only screen and (max-width: 1600px) {
  .content {
    width: 90%;
    padding: 0px;
  }

  .content .info {
    max-width: 400px;
  }

}

@media only screen and (max-width: 700px) {
  .imgMe {
    max-width: 95%;

  }

  .imgPages {
    max-width: 90%;
  }
  p
  {
    font-size: 0.7rem;
  }
  li
  {
    font-size: 1.2rem;
  }

}
.navbar {
    display: flex;
    /*flex-direction: row;*/
    justify-content: center;
    align-items:center;
    min-height: 100px;
    background: rgba(0, 0, 0, 0.377);
    box-shadow: 0px 5px 15px rgba(245, 242, 242, 0.363);
}

.navContent {

    display: flex;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
    flex-wrap: wrap;
    /*align-content: space-around;*/

}

.navContent button {
    position: relative;
    overflow: hidden;  
    /*min-height: 40%;   */
    /*min-width: 20%;*/
    text-align: center;
    text-decoration:none;
    padding: 10px 15px;
    margin: 5px 5%;
    flex-grow: 1;
    background: rgba(190, 187, 187, 0.123);
    transition: all 0.7s;
    border-radius: 10px;
    font-size: 15px;
    cursor: pointer;
    box-shadow: 0px 5px 15px rgba(245, 242, 242, 0.363);
    
    /*animation-delay: 10s;*/
}
.navContent button::before
{
    content: '';
    position: absolute;
    top: 0px;
    left: 10%;   
    /*position: initial;*/
    width: 1.5em;
    height: 100%;
    background:  rgba(255, 255, 255, 0.2);;
    transform: translateX(15em) skewX(-45deg);
    transition: 0;
    animation: backgroundButton 15s infinite;
}

.navContent button:hover {
    box-shadow: 0px 5px 25px rgba(27, 223, 190, 0.61);
    font-size: 19px;
    color: rgba(67, 255, 224, 0.973);
    /*min-height: 50%;*/
    /*min-width: 25%;*/
    /*flex-grow: 1.1;*/
    /*text-decoration:underline;*/
    transform: scale(1.05);
}

@media only screen and (max-width: 1000px) {

    .navContent a {
        font-size: 13px;
    }
    .navContent a:hover {
        font-size: 15px;
    }

}

@media only screen and (max-width: 600px) {
    .navContent {
        width: 100%;
    }

}

@keyframes backgroundButton
{
    from
    {
        background: rgba(241, 10, 10, 0);
        transform:translateX(-5em) skewX(-45deg);

    }

    95%
    {
        background: rgba(255, 255, 255, 0.2);
        transform:translateX(-5em) skewX(-45deg);
        
    }
    to 
    {
        background: rgba(255, 255, 255, 0.2);
        transform: translateX(15em) skewX(-45deg); 
    }
}